<template>
  <div class="icon-container" @click="handleIconClick">
    <div v-if="img" class="icon-image">
      <img :src="img" class="icon-img" :class="extraClass">
    </div>
    <p class="icon-label">
      {{ label }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: null
    },
    img: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    external: {
      type: Boolean,
      default: false
    },
    extraClass: {
      type: String,
      default: ''
    }
  },

  methods: {
    handleIconClick () {
      if (this.to) {
        if (this.external) {
          window.open(this.to, '_blank')
        } else {
          this.$router.push(this.to)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.icon-container {
  aspect-ratio: 1/1;
  flex: 0 0 calc((100% / 3) - (2px / 3));
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-width: calc(33.3333333333% - 0.6666666667px);
  min-height: calc(20%);
  overflow: hidden;
  aspect-ratio: 1/1;
  border: 1px solid #fff;

  @media screen and (min-width: 768px){
      flex: 0 0 calc((100% / 5) - 0.8px);
      min-width: calc((100% / 5) - 0.8px);
      img {
        max-height: 50%;
        width: auto;
        height: auto;

        &.streaming-icon {
          max-height: 75%;
        }
      }
  }
}
.icon-img {
  width: 32px;
  height: 32px;

  &.streaming-icon {
    width: 40px;
    height: 40px;
  }

  @media screen and (min-width: 376px){
    width: 38px;
    height: 38px;

    &.streaming-icon {
      width: 42px;
      height: 42px;
    }
  }
}
.icon-image {
  background: #004182;
  padding: 13px;
  border-radius: 10px;
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;

  @media screen and (min-width: 376px){
    width: 76px;
  }
}
.icon-label {
  color: #004182 !important;
  font-weight: 600;
  padding-top: 5px;
  font-size: 13px;

  @media screen and (min-width: 376px){
    padding-top: 6px;
    font-size: 14px;
  }
}
</style>
