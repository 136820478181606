<template>
  <div>
    <search-bar v-model="searchText" placeholder="Cerca per autori e testo" />
    <div v-if="summaries" class="main-content" ref="mainContent">
      <summary-row
        v-for="(summary, key) in summariesFiltered"
        :key="key"
        :summary="summary"
        @onRowClick="showSummary(summary.id)"
        @rendered="handleRowRendered()"
      />
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SearchBar from '@/components/Search/SearchBar.vue'
import SummaryRow from '@/components/Summaries/SummaryRow.vue'

export default {
  components: {
    SearchBar,
    SummaryRow
  },
  props: ['pageContent'],
  data () {
    return {
      searchText: '',
      renderedRowsCount: 0,
      scrollTop: 0
    }
  },

  computed: {
    ...mapGetters({
      summaries: 'summaries/items'
    }),
    summariesFiltered () {
      if (this.summaries && this.searchText) {
        return this.summaries.filter(item =>
          (item.abstract_title && item.abstract_title.toLowerCase().includes(this.searchText.toLowerCase())) ||
          (item.abstract_keywords && item.abstract_keywords.toLowerCase().includes(this.searchText.toLowerCase())) ||
          (item.abstract_authors && item.abstract_authors.toLowerCase().includes(this.searchText.toLowerCase())) ||
          (item.id_poster && item.id_poster.toLowerCase().includes(this.searchText.toLowerCase()))
        )
      } else {
        return this.summaries
      }
    }
  },

  watch: {
    searchText: {
      handler (val) {
        localStorage.setItem('summariesSearchText', val)
      }
    }
  },

  created () {
    this.$emit('title', 'Abstracts')
    this.fetchSummaries(Object.assign({ sync: true }))
    this.searchText = localStorage.getItem('summariesSearchText') || ''
  },

  methods: {
    ...mapActions({
      fetchSummaries: 'summaries/fetch'
    }),
    handleRowRendered() {
      this.renderedRowsCount++
      if (this.renderedRowsCount === this.summariesFiltered.length) {
        // Tutti i componenti sono stati renderizzati, fai lo scroll
        this.$nextTick(() => {
          if (this.pageContent) {
            this.scrollTop = localStorage.getItem('summariesScrollTop') || 0
            this.pageContent.scrollTo({ top: this.scrollTop, behavior: 'smooth' })
          }
        })
      }
    },
    showSummary(summaryId) {
      localStorage.setItem('summariesScrollTop', this.pageContent.scrollTop)
      this.$router.push(`/summaries/${summaryId}`)
    }
  }
}
</script>

<style lang="scss">
.main-content {
  padding-top: 65px;
}
</style>
