<template>
  <div>
    <search-bar v-model="searchText" />
    <div v-if="speakers" class="main-content">
      <speaker-row
        v-for="(speaker, key) in speakersFiltered"
        :key="key"
        :speaker="speaker"
        @onRowClick="showSpeaker(speaker.id)"
        @rendered="handleRowRendered()"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SearchBar from '@/components/Search/SearchBar.vue'
import SpeakerRow from '@/components/Speakers/SpeakerRow.vue'

export default {
  components: {
    SearchBar,
    SpeakerRow
  },

  props: ['pageContent'],

  data () {
    return {
      searchText: null,
      renderedRowsCount: 0,
      scrollTop: 0
    }
  },

  computed: {
    ...mapGetters({
      speakers: 'speakers/items'
    }),
    speakersFiltered () {
      if (this.speakers && this.searchText) {
        return this.speakers.filter(item =>
          item.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
          item.surname.toLowerCase().includes(this.searchText.toLowerCase()) ||
          item.company.toLowerCase().includes(this.searchText.toLowerCase())
        )
      } else {
        return this.speakers
      }
    }
  },

  watch: {
    searchText: {
      handler (val) {
        localStorage.setItem('speakersSearchText', val)
      }
    }
  },

  created () {
    this.$emit('title', 'Faculty')
    this.fetchSpeakers(Object.assign({ sync: true }))
    this.searchText = localStorage.getItem('speakersSearchText') || ''
  },

  methods: {
    ...mapActions({
      fetchSpeakers: 'speakers/fetch'
    }),
    handleRowRendered() {
      this.renderedRowsCount++
      if (this.renderedRowsCount === this.speakersFiltered.length) {
        // Tutti i componenti sono stati renderizzati, fai lo scroll
        this.$nextTick(() => {
          if (this.pageContent) {
            this.scrollTop = localStorage.getItem('speakersScrollTop') || 0
            this.pageContent.scrollTo({ top: this.scrollTop, behavior: 'smooth' })
          }
        })
      }
    },
    showSpeaker(speakerId) {
      localStorage.setItem('speakersScrollTop', this.pageContent.scrollTop)
      this.$router.push(`/speakers/${speakerId}`)
    }
  }
}
</script>

<style scoped lang="scss">
.main-content {
  padding-top: 65px;
}
</style>
