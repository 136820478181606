<template>
  <div class="venue-wrapper">
    <div v-if="venue" class="venue-info-container">
      <div class="name">
        {{ venue.name }}
      </div>

      <div class="vimeo-container">
        <div style="padding:56.25% 0 0 0;position:relative;">
          <iframe 
            :src="`https://player.vimeo.com/video/${venue.vimeo_embed}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`" 
            frameborder="0" 
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
            style="position:absolute;top:0;left:0;width:100%;height:100%;" 
            :title="`EUGMS2024 - ${venue.name}`">
          </iframe>
        </div>
      </div>

      <div class="open-in-browser-link" @click="openVideoInBrowser">
        Open video in browser
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      venue: 'venues/single'
    })
  },

  created () {
    this.$emit('title', 'Streaming')
    this.venueId = this.$route.params.id
    this.getVenue(this.venueId)
  },

  mounted () {
    const vimeoScript = document.createElement('script')
    vimeoScript.setAttribute('src', 'https://player.vimeo.com/api/player.js')
    document.head.appendChild(vimeoScript)
  },

  beforeDestroy () {
    this.clearVenue()
  },

  methods: {
    ...mapActions({
      getVenue: 'venues/get',
      clearVenue: 'venues/resetSingle'
    }),
    openVideoInBrowser () {
      const videoUrl = `https://player.vimeo.com/video/${this.venue.vimeo_embed}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`
      this.windowParent.postMessage({ type: 'openInAppBrowser', url: videoUrl }, '*')
    }
  }
}
</script>

<style scoped lang="scss">
.venue-wrapper{
  .venue-info-container{
    display: flex;
    flex-direction: column;
    margin-top:25px;

    .name{
      /*font-family: Suisse Intl;*/
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      color: #000;
      text-align: center;
    }

    .vimeo-container {
      margin-top: 25px;
      padding: 0 15px;
    }

    .open-in-browser-link {
      text-decoration: underline;
      text-align: center;
      margin-top: 20px;
      font-size: 17px;
      font-weight: 900;
    }
  }
}
</style>
