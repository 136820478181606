<template>
  <div class="dashboard-container">
    <div class="dashboard-header">
      <div class="logo-container">
        <div class="flex-grow left-box" />
        <div class="transparent-logo">
          <img src="/img/risorse/logo_EuGMS_20.png">
        </div>
        <div class="flex-grow" />
      </div>
      <div class="hero-container" />
    </div>

    <DashboardScroll :icons-paginated="dashboardIconsPaginated" :icons-per-page="iconsPerPage" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DashboardScroll from '@/components/Dashboard/DashboardScroll.vue'

export default {
  components: { DashboardScroll },
  data () {
    return {
      query: {
        rowsPerPage: 1000,
        sort: '-created_at',
        filter: {
          custom_search: '',
          listed: 0
        }
      },
      windowWidth: window.innerWidth,
      dashboardIcons: [
        { label: 'Programme', link: '/sessions-list', img: '/img/risorse/dashboard-programme.svg' },
        { label: 'Faculty', link: '/speakers-list', img: '/img/risorse/dashboard-faculty.svg' },
        { label: 'My agenda', link: '/my-programme', img: '/img/risorse/dashboard-my-programme.svg' },
        { label: 'Abstracts', link: '/summaries', img: '/img/risorse/dashboard-abstract.svg' },
        { label: 'Posters', link: 'https://posterit.it/get-posters/PRKZWDTHJGSYANMI/en', external: true, img: '/img/risorse/dashboard-posters-new.svg' },
        { label: 'On Demand', link: '/streaming', img: '/img/risorse/dashboard-streaming-new.svg', extraClass: 'streaming-icon' },
        { label: 'My notes', link: '/my-notes', img: '/img/risorse/dashboard-my-notes.svg' },
        { label: 'Social events', link: '/social-events-list', img: '/img/risorse/dashboard-social-events.svg' },
        { label: 'Sponsors', link: '/sponsors-list', img: '/img/risorse/dashboard-sponsors.svg' },
        { label: 'General info', link: '/congress-info/general-info', img: '/img/risorse/dashboard-congress-info.svg' },
        { label: 'Tourist info', link: '/congress-info/tourist-info', img: '/img/risorse/dashboard-tourist-info.svg' },
        { label: 'Floorplan', link: '/map', img: '/img/risorse/dashboard-map.svg' },
        { label: 'Social', link: '/social-links-list', img: '/img/risorse/dashboard-social.svg' },
        { label: 'Networking', link: '/search', img: '/img/risorse/dashboard-networking.svg' },
        { label: 'Chat', link: '/messages', img: '/img/risorse/dashboard-chat.svg' },
        { label: 'Notifications', link: '/notifications-list', img: '/img/risorse/dashboard-notifications.svg' }
      ]
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      notifications: 'notifications/items'
    }),
    iconsPerPage () {
      return this.windowWidth >= 768 ? 15 : 9
    },
    dashboardIconsPaginated () {
      return this.dashboardIcons.reduce((acc, val, i) => {
        const idx = Math.floor(i / this.iconsPerPage)
        const page = acc[idx] || (acc[idx] = [])
        page.push(val)
        return acc
      }, [])
    }
  },

  mounted () {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },

  created () {
    this.thisWindow.scrollTo(0, 0)
    localStorage.setItem('summariesSearchText', '')
    localStorage.setItem('sessionsSearchText', '')
    localStorage.setItem('speakersSearchText', '')
    localStorage.setItem('summariesScrollTop', '')
    localStorage.setItem('sessionsScrollTop', '')
    localStorage.setItem('speakersScrollTop', '')
    localStorage.setItem('sponsorsScrollTop', '')
    if (this.user) {
      this.fetchNotifications(Object.assign({ sync: true }, this.query)).then((res) => {
        if (res.data.content.data.length) {
          this.$router.push('/notifications-list')
        }
      })
    }
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
      toggleDarkMode: 'settings/toggleDarkMode',
      fetchNotifications: 'notifications/fetch'
    }),
    doLogout () {
      this.logout().then((res) => {
        // this.$router.push('/login')
      })
    }
  }
}
</script>

<style lang="scss">
img{
  border-radius: 0 !important;
}
.dashboard-container{
  height: calc(100vh - 50px);
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
}
.dashboard-header{
  position: relative;
  flex: 1;

  min-height: 135px;

  @media screen and (min-width: 375px){
    min-height: 185px;
  }

  @media screen and (min-width: 768px){
    max-height: none;
  }
}
.logo-container{
  display: flex;
  width: 100%;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  column-gap: 10px;

  .transparent-logo{
    padding-top: 8px;
  }
}
.logo-container {
  .flex-grow {
    flex-grow: 1;
  }
  .left-box {
    height: 45px;
    background: #002162;
  }
}
.hero-container{
  background: url("/img/risorse/FotoHeader1d.jpg");
  height: 100%;
  //min-height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
