<template>
  <div class="streaming-container">
    <div v-if="!filteredStreamings.length" class="streaming-label">
      No Streaming to Show
    </div>
    <div class="venues-container">
      <div
        v-for="streaming in filteredStreamings"
        :key="streaming.id"
        class="venue-link-container"
        @click="openVideoUrl(streaming)"
      >
        <!-- <RouterLink :to="`/streaming/${venue.id}`">
          {{ venue.name }}
        </RouterLink> -->
        {{ streaming.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      streamings: 'streamings/items'
    }),
    filteredStreamings () {
      return this.streamings.filter(streaming => streaming.video_url != null)
    }
  },
  created () {
    this.$emit('title', 'On Demand')
    this.fetchStreamings()
  },
  methods: {
    ...mapActions({
      fetchStreamings: 'streamings/fetch'
    }),
    openVideoUrl (streaming) {
      this.windowParent.postMessage({ type: 'openInAppBrowser', url: streaming.video_url }, '*')
      // window.open(venue.vimeo_embed, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.streaming-container{
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  padding: 0 0 0 30px;
  height: calc(100vh - 36px);

  .streaming-label{
    font-weight: 900;
    padding: 0 50px;
  }

  .venues-container {
    margin: 15px 0;
  }

  .venue-link-container {
    text-decoration: underline;
    color: darkblue;
    font-size: 17px;
    margin-bottom: 10px;
  }
}
</style>
