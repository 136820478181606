import { render, staticRenderFns } from "./SocialEventRow.vue?vue&type=template&id=927e2862&scoped=true&"
import script from "./SocialEventRow.vue?vue&type=script&lang=js&"
export * from "./SocialEventRow.vue?vue&type=script&lang=js&"
import style0 from "./SocialEventRow.vue?vue&type=style&index=0&id=927e2862&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "927e2862",
  null
  
)

export default component.exports