<template>
  <div>
    <div>
      <social-link-row v-for="(socialLink, key) in socialLinks" :key="key" :social-link="socialLink" />
    </div>
    <div
      v-if="congressInfo"
      class="social-links-description"
      v-html="congressInfo.description"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SocialLinkRow from '@/components/SocialLinks/SocialLinkRow.vue'

export default {
  components: { SocialLinkRow },

  data () {
    return {
      staticPageKey: 'social-links-text'
    }
  },

  computed: {
    ...mapGetters({
      socialLinks: 'socialLinks/items',
      congressInfo: 'congressInfo/single'
    })
  },

  created () {
    this.$emit('title', 'Social')
    this.fetchSocialLinks(Object.assign({ sync: true }))
    this.getCongressInfo(this.staticPageKey)
  },

  beforeDestroy () {
    this.clearCongressInfo()
  },

  methods: {
    ...mapActions({
      fetchSocialLinks: 'socialLinks/fetch',
      getCongressInfo: 'congressInfo/get',
      clearCongressInfo: 'congressInfo/resetSingle'
    })
  }
}
</script>

<style lang="scss">
  .social-links-description {
    padding: 25px;
  }
</style>
