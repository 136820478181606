<template>
  <div v-if="sponsor.description == null" class="sponsor-row" @click="goToSponsor">
    <div class="sponsor-icon-container" :style="sponsorLogoStyle" />
    <div class="labels-container">
      <div>{{ sponsor.name }}</div>
    </div>
    <img src="/img/risorse/arrow-right-blue-new.svg">
  </div>
  <div v-else class="sponsor-row" @click="goToSponsorDescription">
    <div class="sponsor-icon-container" :style="sponsorLogoStyle" />
    <div class="labels-container">
      <div>{{ sponsor.name }}</div>
    </div>
    <img src="/img/risorse/arrow-right-blue-new.svg">
  </div>
</template>

<script>
import router from '@/routes/router'

export default {
  props: {
    sponsor: {
      type: Object,
      default () {
        return {}
      }
    },
    pageContent: null
  },
  mounted() {
    this.$emit('rendered')
  },
  computed: {
    sponsorLogoStyle () {
      const image = (this.sponsor.logo) ? this.sponsor.logo.full_url : '/img/risorse/company.svg'
      return `background-image: url("${image}");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;`
    }
  },
  methods: {
    goToSponsor () {
      if (this.sponsor.website.startsWith('http')) {
        window.open(this.sponsor.website, '_blank')
      } else {
        window.open(`https://${this.sponsor.website}`, '_blank')
      }
    },
    goToSponsorDescription () {
      localStorage.setItem('sponsorsScrollTop', this.pageContent.scrollTop)
      router.push(`/sponsors/${this.sponsor.id}`)
    }
  }
}
</script>

<style scoped lang="scss">
.sponsor-row{
  padding: 20px;
  border-top: 1px solid #F3F3F3;
  border-bottom: 1px solid #F3F3F3;
  border-collapse: collapse;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:nth-of-type(even){
    background-color: #F3F3F3;
  }

  .labels-container{
    margin-left: 30px;
    flex: 1;
  }

  .sponsor-icon-container{
    width: 100px;
    height: 50px;
  }
}
.sponsor-row:active {
  background-color: #c0c0c0;
  border: 1px solid #a1a1a1;
}
</style>
