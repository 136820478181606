<template>
  <div class="social-link-row" @click="goToSocialLink">
    <div class="social-link-icon-container" :style="socialLinkIconStyle" />
    <div class="labels-container">
      <div>{{ socialLink.name }}</div>
    </div>
    <img src="/img/risorse/arrow-right-blue-new.svg">
  </div>
</template>

<script>
export default {
  props: {
    socialLink: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    socialLinkIconStyle () {
      if (this.socialLink.logo && this.socialLink.logo.full_url) {
        return `background-image: url("${this.socialLink.logo.full_url}");
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;`
      }
      return ''
    }
  },
  methods: {
    goToSocialLink () {
      window.open(this.socialLink.url, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.social-link-row{
  padding: 20px;
  border-top: 1px solid #F3F3F3;
  border-bottom: 1px solid #F3F3F3;
  border-collapse: collapse;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:nth-of-type(even){
    background-color: #F3F3F3;
  }

  .labels-container{
    margin-left: 30px;
    flex: 1;
  }

  .social-link-icon-container{
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}
.social-link-row:active {
  background-color: #c0c0c0;
  border: 1px solid #a1a1a1;
}
</style>
