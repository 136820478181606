<template>
  <div class="container-fluid">
    <div class="col-12 logo-img-container">
      <img class="home-logo" src="img/risorse/EuGMSlogoVerticale.png">
    </div>
    <div class="col-12 p-0 pt-2">
      <div class="list-group">
        <router-link to="/home/congress-info/eugms-presentation" class="list-group-item list-group-item-action list-group-main">
          EuGMS
        </router-link>
      </div>
      <div class="list-group">
        <router-link to="/home/congress-info/myeugms-presentation" class="list-group-item list-group-item-action list-group-main">
          MyEuGMS
        </router-link>
        <router-link to="/dashboard" class="list-group-item list-group-item-action list-group-element yellow-color">
          20<sup>th</sup> Annual Congress - Valencia
        </router-link>
        <router-link to="/home/next-congress" class="list-group-item list-group-item-action list-group-element lightgreen-color">
          21<sup>st</sup> Annual Congress - Reykjavik
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'HomePage',
  data () {
    return {
      query: {
        rowsPerPage: 1000,
        sort: '-created_at',
        filter: {
          custom_search: '',
          listed: 0
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  created () {
    if (this.user) {
      this.fetchNotifications(Object.assign({ sync: true }, this.query)).then((res) => {
        if (res.data.content.data.length) {
          this.$router.push('/notifications-list')
        }
      })
    }
  },
  methods: {
    ...mapActions({
      fetchNotifications: 'notifications/fetch'
    })
  }
}
</script>

<style scoped>
.container-fluid {
  background: url(/img/risorse/BackgroundPaginaMenu-2048x2732.jpg);
  background-size: cover;
  background-position: center;
  height: 100%;
  padding: 0px;
  padding-top: 40px !important;
}
.logo-img-container {
  display: flex;
  justify-content: center;
  margin: 30px auto 10px;
}
.home-logo {
  width: 55%;
}
.list-group {
    border-radius: 0px;
    font-family: Poppins, sans-serif;
}
.list-group a {
    font-weight: 600;
    font-size: 18px;
    margin: 0 0 4px;
}
.list-group .list-group-main {
    background-color: #129cda;
    color: white;
    border: none;
}
.list-group .list-group-element {
    color: black;
}
.yellow-color {
  background: #004182;
  color: white !important;
  border: none;
}
.lightgreen-color {
  background: #8ac653;
  border: none;
}
/* .list-group .list-group-element:nth-child(odd) {
    background-color: rgb(231,235,236);
}
.list-group .list-group-element:nth-child(even) {
    background-color: rgb(202,208,213);
} */
</style>
