<template>
  <div class="user-row" @click="$emit('onRowClick')">
    <div class="labels-container">
      <div>{{ user.first_name }} {{ user.last_name }}</div>
    </div>
    <img src="/img/risorse/arrow-right-blue-new.svg">
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style scoped lang="scss">
.user-row{
  padding: 6px;
  border-top: 1px solid #F3F3F3;
  border-bottom: 1px solid #F3F3F3;
  border-collapse: collapse;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:nth-of-type(even){
    background-color: #F3F3F3;
  }
  .labels-container{
    padding: 4px 8px 4px 4px;
    flex: 1;
    color: #808080;
  }
}
.user-row:active {
    background-color: #c0c0c0;
    border: 1px solid #a1a1a1;
}
</style>
