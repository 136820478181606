<template>
  <div class="map-container">
    Please tap on the map to zoom in/out
    <br><br>
    <div class="floor-label" @click="windowParent.postMessage(inAppBrowserObject('/img/eugms-map1.jpg'), '*');">
      Ground Floor
    </div>
    <img
      src="/img/eugms-map1.jpg"
      style="width: 200%"
      @click="windowParent.postMessage(inAppBrowserObject('/img/eugms-map1.jpg'), '*');"
    >
    <div class="floor-label" @click="windowParent.postMessage(inAppBrowserObject('/img/eugms-map2.jpg'), '*');">
      First Floor
    </div>
    <img
      src="/img/eugms-map2.jpg"
      style="width: 200%"
      @click="windowParent.postMessage(inAppBrowserObject('/img/eugms-map2.jpg'), '*');"
    >
    <div class="floor-label" @click="windowParent.postMessage(inAppBrowserObject('/img/eugms-map3.jpg'), '*');">
      Second Floor
    </div>
    <img
      src="/img/eugms-map3.jpg"
      style="width: 200%"
      @click="windowParent.postMessage(inAppBrowserObject('/img/eugms-map3.jpg'), '*');"
    >
  </div>
</template>

<script>
export default {
  created () {
    this.$emit('title', 'Floorplan')
  },
  methods: {
    inAppBrowserObject (path) {
      const imageUrl = `${process.env.VUE_APP_BASE_URL}${path}`
      return { type: 'openInAppBrowser', url: imageUrl }
    }
  }
}
</script>

<style scoped lang="scss">
.map-container{
  padding: 20px;
  text-align: center;
}
.floor-label {
  font-weight: 900;
  font-size: 16px;
  text-decoration: underline;
}
</style>
