<template>
  <div>
    <social-event-row v-for="(socialEvent, key) in socialEvents" :key="key" :social-event="socialEvent" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SocialEventRow from '@/components/SocialEvents/SocialEventRow.vue'

export default {
  components: { SocialEventRow },

  computed: {
    ...mapGetters({
      socialEvents: 'socialEvents/items'
    })
  },

  created () {
    this.$emit('title', 'Social Events')
    this.fetchSocialEvents(Object.assign({ sync: true }))
  },

  methods: {
    ...mapActions({
      fetchSocialEvents: 'socialEvents/fetch'
    })
  }
}
</script>

<style scoped lang="scss">
.days-row{
  display: flex;
  justify-content: space-around;
  padding: 10px;
}
</style>
