<template>
  <div>
    <div class="search-container">
      <div class="search-text">
        <base-input
          v-model="searchText"
          required
          placeholder="Search"
          type="text"
          class="search-input"
        />
      </div>
      <img src="/img/risorse/ico-search.svg">
    </div>
    <div v-if="sponsors" class="main-content">
      <sponsor-row v-for="(sponsor, key) in sponsorsFiltered"
         :key="key"
         :sponsor="sponsor"
         :page-content="pageContent"
         @rendered="handleRowRendered()"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SponsorRow from '@/components/Sponsors/SponsorRow.vue'

export default {
  components: { SponsorRow },

  props: ['pageContent'],

  data () {
    return {
      searchText: '',
      renderedRowsCount: 0,
      scrollTop: 0
    }
  },

  computed: {
    ...mapGetters({
      sponsors: 'sponsors/items'
    }),
    sponsorsFiltered () {
      if (this.sponsors && this.searchText) {
        return this.sponsors.filter(item =>
          (item.name && item.name.toLowerCase().includes(this.searchText.toLowerCase()))
        )
      } else {
        return this.sponsors
      }
    }
  },

  created () {
    this.$emit('title', 'Sponsors')
    this.fetchSponsors(Object.assign({ sync: true }))
  },

  methods: {
    ...mapActions({
      fetchSponsors: 'sponsors/fetch'
    }),
    handleRowRendered() {
      this.renderedRowsCount++
      if (this.renderedRowsCount === this.sponsorsFiltered.length) {
        // Tutti i componenti sono stati renderizzati, fai lo scroll
        this.$nextTick(() => {
          if (this.pageContent) {
            this.scrollTop = localStorage.getItem('sponsorsScrollTop') || 0
            this.pageContent.scrollTo({ top: this.scrollTop, behavior: 'smooth' })
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.search-container {
  padding: 10px 15px 15px;
  height: 65px;
  width: 100%;
  position: absolute;
  display: flex;
  background: #fff;
  align-items: center;

  .search-text {
    flex-grow: 1;

    .form-group {
      margin-right: 15px;
      margin-bottom: 0px;
    }
  }

  img {
    width: 30px;
    margin-right: 5px;
  }
}

.main-content {
  padding-top: 65px;
}
</style>
