<template>
  <div class="speech-row">
    <div class="speech-row-info" @click="expandRow">
      <div class="speech-container">
        <div class="speech-title-container">
          <div class="speech-title">
            {{ speech.title }}
          </div>
        </div>
        <div class="speech-details-container">
          <div v-if="withSessionInfo" class="session-info">
            Session: {{ speech.session.title }}
          </div>
          <div v-if="withSessionInfo">
            <img src="/img/risorse/icon-calendar.svg">
            {{ speech.date_start | formatDate }}
          </div>
          <div class="speech-time-container">
            <img src="/img/risorse/icon-clock.svg">
            {{ speech.date_start | formatTime }} - {{ speech.date_end | formatTime }}
          </div>
          <div class="speakers-summary-container">
            {{ speakersSummary }}
          </div>
        </div>
      </div>
      <div class="speech-icon">
        <img :class="expanded ? 'rotate-arrow' : null" src="/img/risorse/arrow-right-blue-new.svg">
      </div>
    </div>
    <div v-show="expanded" class="speech-row-speakers">
      <div class="description-container" v-html="speech.description" />
      <div v-if="speech.speakers.length > 0" class="speakers-container">
        <div class="speakers-label">
          Faculty:
        </div>
        <div v-if="speech.speakers.length > 0">
          <speaker-row
            v-for="(speaker, index) in speech.speakers"
            :key="index"
            :speaker="speaker"
            @onRowClick="$router.push(`/speakers/${speaker.id}`)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SpeakerRow from '@/components/Speakers/SpeakerRow.vue'

export default {
  components: { SpeakerRow },
  filters: {
    formatDate (value) {
      const date = new Date(value)
      const day = String(date.getDate()).padStart(2, '0')
      const month = date.toLocaleString('EN', { month: 'long' })
      const year = date.getFullYear()
      return `${String(day)} ${String(month)} ${String(year)}`
    },

    formatTime (value) {
      const date = new Date(value)
      const hours = date.getHours()
      const minutes = date.getMinutes()
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
    }
  },
  props: {
    speech: {
      type: Object,
      default () {
        return {}
      }
    },
    withSessionInfo: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      expanded: false
    }
  },

  computed: {
    speakersSummary () {
      let summary = ''
      this.speech.speakers.forEach((speaker, index) => {
        summary += speaker.name + ' ' + speaker.surname
        if (this.speech.speakers.length > 0 && index < (this.speech.speakers.length - 1)) {
          summary += ', '
        }
      })
      return summary
    }
  },

  watch: {
    speech: {
      handler (val) {
        this.isFavourite = val.favourite
      },
      immediate: true
    }
  },

  methods: {
    expandRow () {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style lang="scss" scoped>
.speech-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  &:nth-of-type(odd) {
    background-color: #F3F3F3;
  }

  .speech-row-info {
    width: 100%;
    display: flex;
  }
  .speech-container {
    flex: 1;
    padding: 10px 20px;
  }
  .description-container {
    padding: 10px 20px 10px;
  }
  .speakers-summary-container {
    padding-top: 10px;
  }

  .speech-title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      >div {
          display: flex;
          align-items: center;
      }
      .speech-title {
          font-size: 14px;
          font-weight: 600;
          color: #000;
      }
      .text-light-blue {
          color: #1c989f;
      }
      .text-orange {
          color: #d75200;
      }
      .favourite {
          color: #000;
          letter-spacing: 0.16px;
          font-size: 16px;

          IMG {
            width: 30px;
            max-width: 30px;
            padding: 4px;
          }
      }
      .note {
          color: #000;
          letter-spacing: 0.16px;
          font-size: 16px;
          margin-right: 10px;

          IMG {
            width: 26px;
            max-width: 30px;
            padding: 4px;
            margin-right: 5px;
            filter: invert(1);
          }
      }
  }
  .speech-details-container {
    display: flex;
    justify-content: space-between;
    color: #646363;
    flex-direction: column;

    .speech-time-container {
      padding-top: 10px;
    }

    .speech-room{
      font-size: 14px;
    }
    .speech-to {
      font-size: 14px;
    }
  }
  .speech-icon {
    padding: 20px;
    display: flex;

    img {
      height: 20px;

      &.rotate-arrow {
        rotate: 90deg;
      }
    }
  }

  .speech-row-speakers {
    width: 100%;

    .speakers-container{
      .speakers-label {
        padding: 10px 20px;
        color: #000;
        font-weight: 600;
      }
    }
  }
}
.speech-row:nth-of-type(odd) .speaker-row:nth-of-type(even) {
  background-color: #F3F3F3 !important;
  border: none;
}
.speech-row:nth-of-type(odd) .speaker-row:nth-of-type(odd) {
  background-color: #F3F3F3 !important;
  border: none;
}
.speech-row:nth-of-type(even) .speaker-row:nth-of-type(even) {
  background-color: #fff !important;
  border: none;
}
.speech-row:nth-of-type(even) .speaker-row:nth-of-type(odd) {
  background-color: #fff !important;
  border: none;
}
.speaker-row:active {
  background-color: none !important;
  border: none !important;
}
.speech-row:active {
  background-color: #c0c0c0;
}
</style>
