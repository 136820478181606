<template>
  <div class="image-container" :style="sponsorImageStyle" />
</template>

<script>
export default {
  name: 'SponsorImage',

  props: {
    sponsor: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    sponsorImageStyle () {
      const image = (this.sponsor.logo) ? this.sponsor.logo.full_url : '/img/risorse/company.svg'
      return `background-image: url("${image}");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;`
    }
  }
}
</script>

<style scoped>
  .image-container{
    width: 200px;
    height: 100px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
</style>
