<template>
  <div class="session-row" @click="$emit('onRowClick')">
    <div class="session-container">
      <div class="session-title-container">
        <div
          :class="'session-title ' + ((session.venue && session.venue.css_class) ? session.venue.css_class : '')"
          :style="session.custom_color ? `color:${session.custom_color}` : null"
        >
          {{ session.title }}
        </div>
        <div class="icons">
          <div class="note" @click.stop="$emit('onNoteClick')">
            <img src="/img/risorse/dashboard-my-notes.svg" alt="note">
          </div>
          <div class="favourite" @click.stop="toggleFavourite">
            <img :src="'/img/risorse/icon-heart-' + ((isFavourite) ? 'yes' : 'no') + '.svg'" alt="favourite">
          </div>
        </div>
      </div>
      <div v-if="!speakerDetails" class="session-details-container">
        <div class="session-room">
          {{ session.venue.name }}
        </div>
        <div class="session-to">
          <img src="/img/risorse/icon-clock.svg">
          {{ session.date_start | formatTime }} - {{ session.date_end | formatTime }}
        </div>
      </div>
      <div v-else class="session-details-container">
        <div>
          <img src="/img/risorse/icon-calendar.svg">
          {{ session.date_start | formatDate }}
        </div>
        <div>
          <img src="/img/risorse/icon-clock.svg">
          {{ session.date_start | formatTime }} - {{ session.date_end | formatTime }}
        </div>
      </div>
    </div>
    <div class="session-icon">
      <img src="/img/risorse/arrow-right-blue-new.svg">
    </div>
  </div>
</template>

<script>
export default {
  filters: {
    formatDate (value) {
      const date = new Date(value)
      const day = String(date.getDate()).padStart(2, '0')
      const month = date.toLocaleString('EN', { month: 'long' })
      const year = date.getFullYear()
      return `${String(day)} ${String(month)} ${String(year)}`
    },

    formatTime (value) {
      const date = new Date(value)
      const hours = date.getHours()
      const minutes = date.getMinutes()
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
    }
  },
  props: {
    session: {
      type: Object,
      default () {
        return {}
      }
    },
    speakerDetails: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isFavourite: false
    }
  },
  mounted() {
    this.$emit('rendered')
  },
  watch: {
    session: {
      handler (val) {
        this.isFavourite = val.favourite
      },
      immediate: true
    }
  },
  methods: {
    toggleFavourite () {
      this.isFavourite = !this.isFavourite
      this.$emit('onFavouriteClick', this.isFavourite)
    }
  }
}
</script>

<style lang="scss" scoped>
.session-row {
  display: flex;
  justify-content: space-between;
  align-items: start;
  &:nth-of-type(odd) {
    background-color: #F3F3F3;
  }

  .session-container {
    flex: 1;
    padding: 10px 0px 10px 20px;
  }

  .session-title-container {
      display: flex;
      justify-content: space-between;
      align-items: start;
      >div {
          display: flex;
          align-items: center;
      }
      .session-title {
          padding-right: 10px;
          font-size: 14px;
          font-weight: 600;
          color: #000;
      }
      .text-light-blue {
          color: #1c989f;
      }
      .text-orange {
          color: #d75200;
      }
      .favourite {
          color: #000;
          letter-spacing: 0.16px;
          font-size: 16px;

          IMG {
            width: 30px;
            max-width: 30px;
            padding: 4px;
          }
      }
      .note {
          color: #000;
          letter-spacing: 0.16px;
          font-size: 16px;
          margin-right: 5px;

          IMG {
            width: 26px;
            max-width: 30px;
            height: 29px;
            padding: 4px;
            filter: invert(1);
          }
      }
  }
  .session-details-container {
    display: flex;
    justify-content: space-between;
    color: #646363;
    padding-top: 10px;

    .session-room{
      font-size: 14px;
    }
    .session-to {
      font-size: 14px;
    }
  }
  .session-icon {
    padding: 14px 20px 14px 12px;
  }
}
.session-row:active {
    background-color: #c0c0c0;
    border: 1px solid #a1a1a1;
}
</style>
