<template>
  <div class="summary-wrapper">
    <div v-if="summary" class="summary-info-container">
      <div class="title-container">
        <div class="title">
          <span v-if="summary.id_poster">
            [{{ summary.id_poster }}]
          </span>
          {{ summary.abstract_title }}
        </div>
      </div>
      <div v-if="summary.type" class="type-container">
        <div class="type-label">
          Type:
        </div>
        <div class="type">
          {{ summary.type }}
        </div>
      </div>
      <div v-if="summary.room" class="room-container">
        <div class="room-label">
          Room:
        </div>
        <div class="room">
          {{ summary.room }}
        </div>
      </div>
      <div v-if="summary.date_time" class="date_time-container">
        <div class="date_time-label">
          Date Time:
        </div>
        <div class="date_time">
          {{ summary.date_time }}
        </div>
      </div>
      <div v-if="summary.abstract_area" class="abstract_area-container">
        <div class="abstract_area-label">
          Area:
        </div>
        <div class="abstract_area">
          {{ summary.abstract_area }}
        </div>
      </div>
      <div class="authors-container">
        <div class="authors-label">
          Authors:
        </div>
        <div class="authors">
          <ul>
            <li v-for="(author, key) in summary.abstract_authors" :key="key">
              {{ author.full_name }}
              <span v-if="author.affiliations.length">
                ({{ author.affiliations.map(aff => aff.name).join(', ') }})
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="body-container">
        <div class="body" v-html="summary.abstract_body" />
      </div>
      <div v-if="summary.user" class="link-container">
        <div
          v-if="summary.user.chat_consent"
          class="link-button dx"
          @click="$router.push(`/messages/${summary.user.id}`)"
        >
          CHAT
        </div>
      </div>
      <div v-if="summary.attachment" class="button-container">
        <base-button class="mb-2" size="lg" @click="onDownloadPosterClick">
          Poster
        </base-button>
      </div>
      <div class="share-container">
        <img src="/img/risorse/icon-share.svg" @click="onShareActionClick">
      </div>
    </div>
  </div>
</template>

<script>
import { size } from 'lodash';
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      summary: 'summaries/single'
    }),

    shareObject () {
      const subject = 'An abstract from the EuGMS congress'
      const body = `${subject} \n\n  ${this.summary.abstract_title} \n\n ${this.summary.abstract_body}`
      const attachments = this.summary.attachment ? [this.summary.attachment.full_url] : []
      return { subject, body, attachments }
    }
  },

  created () {
    this.$emit('title', 'Abstracts')
    this.summaryId = this.$route.params.id
    this.getSummary(this.summaryId)
  },

  beforeDestroy () {
    this.clearSummary()
  },

  methods: {
    ...mapActions({
      getSummary: 'summaries/get',
      clearSummary: 'summaries/resetSingle'
    }),
    onDownloadPosterClick () {
      window.open(this.summary.attachment.full_url, '_blank')
    },
    onShareActionClick () {
      this.windowParent.postMessage({ type: 'openAbstractShare', data: this.shareObject }, '*')
    }
  }
}
</script>

<style scoped lang="scss">
.summary-wrapper{
  .summary-info-container{
    display: flex;
    flex-direction: column;

    .title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0;
      padding: 0 20px;

      .title{
        /*font-family: Suisse Intl;*/
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.16px;
        color: #000;
      }
    }

    .authors-container {
      padding: 0 20px;
      .authors-label {
        margin-bottom: 2px;
        font-weight: 900;
      }
      .authors {
        ul {
          padding: 0;
          li {
            list-style: none;
          }
        }
      }
    }

    .room-container {
      padding: 0 20px;
      .room-label {
        margin-bottom: 2px;
        font-weight: 900;
      }
      .room {
        margin-bottom: 10px;
        color: #1d253b;
      }
    }

    .type-container {
      padding: 0 20px;
      .type-label {
        margin-bottom: 2px;
        font-weight: 900;
      }
      .type {
        margin-bottom: 10px;
        color: #1d253b;
      }
    }

    .date_time-container {
      padding: 0 20px;
      .date_time-label {
        margin-bottom: 2px;
        font-weight: 900;
      }
      .date_time {
        margin-bottom: 10px;
        color: #1d253b;
      }
    }

    .abstract_area-container {
      padding: 0 20px;
      .abstract_area-label {
        margin-bottom: 2px;
        font-weight: 900;
      }
      .abstract_area {
        margin-bottom: 10px;
        color: #1d253b;
      }
    }

    .body-container {
      padding: 0 20px;
      margin: 10px 0;
      /*.body {
        text-align: justify;
      }*/
    }

    .link-container {
      text-align: center;
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
    }
    .link-button {
      width: 200px;
      height: 40px;
      text-align: center;
      margin-top: 10px;
      color: white;
      font-weight: bold;
    }
    .link-button.sx {
      background-color: #102785;
      padding-top: 10px;
    }
    .link-button.dx {
      background-color: #52b729;
      padding-top: 10px;
    }

    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px 0 15px;
    }

    .share-container {
      display: flex;
      justify-content: center;
      margin: 15px 0 30px;

      img {
        width: 35px;
      }
    }
  }
}
</style>
