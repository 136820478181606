<template>
  <div class="speaker-row" @click="$emit('onRowClick')">
    <div class="speaker-icon-container" :style="speakerIconStyle" />
    <div class="labels-container">
      <div class="name-container">
        {{ speaker.name }} {{ speaker.surname }}
      </div>
      <div>{{ speaker.company }}</div>
    </div>
    <img src="/img/risorse/arrow-right-blue-new.svg">
  </div>
</template>

<script>
export default {
  props: {
    speaker: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  mounted() {
    this.$emit('rendered')
  },
  computed: {
    speakerIconStyle () {
      const image = (this.speaker.profile_image) ? this.speaker.profile_image.full_url : '/img/risorse/avatar-man.svg'
      return `background-image: url("${image}");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;`
    }
  }
}
</script>

<style scoped lang="scss">
.speaker-row{
  padding: 20px;
  border-top: 1px solid #F3F3F3;
  border-bottom: 1px solid #F3F3F3;
  border-collapse: collapse;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:nth-of-type(odd){
    background-color: #F3F3F3 !important;
  }

  .labels-container{
    margin-left: 30px;
    padding-right: 20px;
    flex: 1;
    color: #808080;

    .name-container {
      font-size: 14px;
      font-weight: 600;
      color: #000;
    }
  }

  .speaker-icon-container{
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}
.speaker-row:active {
    background-color: #c0c0c0;
    border: 1px solid #a1a1a1;
}
</style>
