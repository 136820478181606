<template>
  <div>
    <div v-if="notifications.length > 0">
      <notification-row v-for="(notification, key) in notifications" :key="key" :notification="notification" />
    </div>
    <div v-else>
      <div class="no-data">
        No notifications yet...
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NotificationRow from '@/components/Notifications/NotificationRow.vue'

export default {
  components: { NotificationRow },

  data () {
    return {
      query: {
        user_id: null
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      notifications: 'notifications/items'
    })
  },

  created () {
    this.$emit('title', 'Notifications')
    this.fetchNotifications(Object.assign({ sync: true })).then((res) => {
      this.setAllNotificationsListed()
    })
  },

  methods: {
    ...mapActions({
      fetchNotifications: 'notifications/fetch',
      setAllNotificationsListed: 'notifications/setAllNotificationsListed'
    })
  }
}
</script>

<style lang="scss">
.no-data{
  font-size: 14px;
  line-height: 18px;
  color: #646363;
  padding: 20px;
}
</style>
