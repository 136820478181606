<template>
  <div>
    <div class="days-row">
      <day-container
        v-for="(date, key) in dateSummary"
        :key="key"
        :date="date"
        :selected="daySelected == key"
        :active="!searchText"
        :page-content="pageContent"
        @daySelected="selectDay(key)"
      />
    </div>
    <div class="search-row">
      <search-bar v-model="searchText" placeholder="Search for authors and keywords" />
    </div>
    <div class="main-content">
      <session-row
        v-for="session in filteredSessions"
        :key="session.id"
        :session="session"
        :hide-date="!searchText"
        @onNoteClick="handleNoteClick(session)"
        @onFavouriteClick="toggleFavourite(session, $event)"
        @onRowClick="openSession(session.id)"
        @rendered="handleRowRendered()"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import SearchBar from '@/components/Search/SearchBar.vue'
import DayContainer from '@/components/Sessions/DayContainer.vue'
import SessionRow from '@/components/Sessions/SessionRow.vue'

export default {
  components: { DayContainer, SessionRow, SearchBar },

  props: ['pageContent'],

  data () {
    return {
      searchText: '',
      daySelected: null,
      renderedRowsCount: 0,
      scrollTop: 0
    }
  },

  computed: {
    ...mapGetters({
      sessions: 'sessions/items',
      user: 'auth/user'
    }),

    dateSummary () {
      const dates = []
      this.sessions.forEach((session) => {
        const dateFormatted = new Date(session.date_start).toISOString().substring(0, 10)
        if (!dates.includes(dateFormatted)) {
          dates.push(dateFormatted)
        }
      })
      return dates
    },

    filteredSessions () {
      if (this.sessions && this.searchText) {
        const searchLower = this.searchText.toLowerCase()
        return this.sessions.filter((session) => {
          const titleSearch = session.title.toLowerCase().includes(searchLower)
          const moderatorSearch = session.moderators.some((moderator) => {
            return `${moderator.name} ${moderator.surname}`.toLocaleLowerCase().includes(searchLower)
          })
          const speechesTitleDescriptionSearch = session.speeches.some((speech) => {
            return speech.title.toLocaleLowerCase().includes(searchLower) || speech.description.toLocaleLowerCase().includes(searchLower)
          })
          const speechesSpeakersSearch = session.speeches.map((speech) => {
            return speech.speakers
          }).flat().some((speaker) => {
            return `${speaker.name} ${speaker.surname}`.toLocaleLowerCase().includes(searchLower)
          })

          return titleSearch || moderatorSearch || speechesSpeakersSearch || speechesTitleDescriptionSearch
        })
      } else {
        const dayKey = this.daySelected > this.dateSummary.length ? 0 : this.daySelected
        return this.sessions.filter((session) => {
          return session.date_start.includes(this.dateSummary[dayKey])
        })
      }
    }
  },

  watch: {
    searchText: {
      handler (val) {
        localStorage.setItem('sessionsSearchText', val)
      }
    }
  },

  created () {
    this.$emit('title', 'Programme')
    this.fetchSessions(Object.assign({ sync: true }))

    if (localStorage.getItem('daySelected')) {
      this.selectDay(localStorage.getItem('daySelected'))
    } else {
      this.selectDay(0)
    }

    this.searchText = localStorage.getItem('sessionsSearchText') || ''
  },

  methods: {
    ...mapActions({
      fetchSessions: 'sessions/fetch'
    }),

    selectDay (key) {
      this.daySelected = key
      this.searchText = ''
      localStorage.setItem('daySelected', key)
    },

    handleNoteClick (session) {
      if (session.note && session.note.id) {
        this.$router.push(`/my-notes/${session.note.id}`)
      } else {
        this.$router.push(`/my-notes/new?session_id=${session.id}`)
      }
    },

    toggleFavourite (session, isFavourite) {
      if (!this.user) {
        this.$router.push({ name: 'Login', query: { redirect: `/sessions/${session.id}` } })
      } else {
        const favourite = isFavourite ? 1 : 0
        axios.put(`/user/${session.id}/favourite-session/${favourite}`).then((response) => {
          this.fetchSessions(Object.assign({ sync: true }))
        }, () => {})
      }
    },
    handleRowRendered() {
      this.renderedRowsCount++
      if (this.renderedRowsCount === this.filteredSessions.length) {
        // Tutti i componenti sono stati renderizzati, fai lo scroll
        this.$nextTick(() => {
          if (this.pageContent) {
            this.scrollTop = localStorage.getItem('sessionsScrollTop') || 0
            this.pageContent.scrollTo({ top: this.scrollTop, behavior: 'smooth' })
          }
        })
      }
    },
    openSession(sessionId) {
      localStorage.setItem('sessionsScrollTop', this.pageContent.scrollTop)
      this.$router.push(`/sessions/${sessionId}`)
    }
  }
}
</script>

<style scoped lang="scss">
.days-row{
  display: flex;
  justify-content: space-around;
  padding: 10px;
  overflow-x: auto !important;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  height: 65px;
  position: absolute;
  background: #fff;
  z-index: 2;
  width: 100%;
}

.days-row::-webkit-scrollbar {
  display: none;
}

.search-row{
  height: 66px;
  top: 111px;
  position: absolute;
  z-index: 2;
  width: 100%;
}

.main-content {
  padding-top: 130px;
}
</style>
