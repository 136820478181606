<template>
  <div class="image-container" :style="speakerImageStyle" />
</template>

<script>
export default {
  name: 'SpeakerImage',

  props: {
    speaker: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    speakerImageStyle () {
      const image = (this.speaker.profile_image) ? this.speaker.profile_image.full_url : '/img/risorse/avatar-man.svg'
      return `background-image: url("${image}");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;`
    }
  }
}
</script>

<style scoped>
  .image-container{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-top: 0px;
    margin-bottom: 20px;
  }
</style>
