<template>
  <div class="note-row" @click="$emit('onRowClick')">
    <div class="labels-container">
      <div>
        <small>
          <i>{{ note.updated_at }}</i>
          <span v-if="note.session">
            - Session:
            <i>{{ note.session.title }}</i>
          </span>
        </small>
      </div>
      <div class="title-container">
        {{ note.title }}
      </div>
    </div>
    <div class="arrow-icon-container">
      <img src="/img/risorse/arrow-right-blue-new.svg">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    note: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<style scoped lang="scss">
.note-row{
  padding: 20px 20px 20px 50px;
  border-top: 1px solid #F3F3F3;
  border-bottom: 1px solid #F3F3F3;
  border-collapse: collapse;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:nth-of-type(even){
    background-color: #F3F3F3;
  }

  .labels-container{
    width: calc(100% - 30px);
    flex: 1;

    .title-container {
      width: calc(100% - 30px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.note-row:active {
  background-color: #c0c0c0;
  border: 1px solid #a1a1a1;
}
</style>
