<template>
  <div class="message-row">
    <div class="labels-container">
      <div :class="(message.sender_or_receiver == 's') ? 'sent' : 'received'">
        {{ message.message }}<br>
        <span class="datetime">{{ message.created_at_fmt }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      default () {
        return {}
      }
    },
    last: {
      type: Boolean,
      default: false
    },
    newMessage: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    if (this.last) {
      this.$emit('scrollBottom')
    }
  }
}
</script>

<style scoped lang="scss">
.message-row{
  padding: 10px 15px;
  border-top: 1px solid #F3F3F3;
  border-bottom: 1px solid #F3F3F3;
  border-collapse: collapse;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:nth-of-type(even){
    background-color: #F3F3F3;
  }

  .labels-container{
    margin: 0;
    flex: 1;
    color: #808080;
  }

  .sent {
    text-align: right;
    margin-right: 6px;
    margin-left: 30px;
  }
  .received {
    text-align: left;
    margin-right: 30px;
    margin-left: 6px;
  }
  .datetime {
    font-style: italic;
    font-size: 11px;
  }
}
.message-row:active {
    background-color: #c0c0c0;
    border: 1px solid #a1a1a1;
}
</style>
