<template>
   <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <card class="card-login card-white" style="margin-top: 18px;">
        <div>
          <div class="text-center">
            <h4 class="text-center">
              ATTENTION
            </h4>
            Sponsors do not have access to this page.
          </div>
        </div>
      </card>
    </div>
   </div>
</template>

<script>
export default {
  created () {
    this.$emit('title', 'EuGMS')
  },
}
</script>
