<template>
  <div v-if="session" class="session-wrapper">
    <div class="title-container">
      <div :class="'title ' + ((session.venue.css_class) ? session.venue.css_class : '')">
        <b>{{ session.title }}</b>
      </div>
      <div class="icons">
        <div class="note" @click.stop="handleNoteClick">
          <img src="/img/risorse/dashboard-my-notes.svg" alt="note">
        </div>
        <div class="favourite" @click="toggleFavourite">
          <img :src="'/img/risorse/icon-heart-' + ((isFavourite) ? 'yes' : 'no') + '.svg'" alt="favourite">
        </div>
      </div>
    </div>
    <div class="time-container">
      <div>
        <img src="/img/risorse/icon-calendar.svg">
        {{ session.date_start | formatDate }}
      </div>
      <div>
        <img src="/img/risorse/icon-clock.svg">
        {{ hours }}
      </div>
    </div>
    <div class="time-container">
      <div>
        <img src="/img/risorse/dashboard-map-grey.svg" style="height: 15px; padding-left: 4px; margin-right: 12px">
        {{ session.venue.name }}
      </div>
    </div>
    <div v-if="session.moderators.length > 0" class="description-chair-container">
      <b>Chair: </b>
      <span v-for="(moderator, key) in session.moderators" :key="key" @click="$router.push(`/speakers/${moderator.id}`)">
        {{ moderator.name }} {{ moderator.surname }}
      </span>
    </div>

    <div v-if="session.speeches.length > 0" class="speeches-container">
      <div class="speeches-label">
        Lectures:
      </div>
      <div>
        <speech-row
          v-for="(speech, index) in session.speeches"
          :key="index"
          :speech="speech"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import SpeechRow from '@/components/Speeches/SpeechRow.vue'
import router from '@/routes/router'

export default {
  components: { SpeechRow },

  filters: {
    formatDate (value) {
      const date = new Date(value)
      const day = String(date.getDate()).padStart(2, '0')
      const month = date.toLocaleString('EN', { month: 'long' })
      const year = date.getFullYear()
      return `${String(day)} ${String(month)} ${String(year)}`
    }
  },

  data () {
    return {
      isFavourite: false
    }
  },

  computed: {
    ...mapGetters({
      session: 'sessions/single',
      user: 'auth/user'
    }),
    hours () {
      const dateStart = new Date(this.session.date_start)
      const dateEnd = new Date(this.session.date_end)
      const hoursStart = dateStart.getHours()
      const minutesStart = dateStart.getMinutes()
      const hoursEnd = dateEnd.getHours()
      const minutesEnd = dateEnd.getMinutes()
      return `${String(hoursStart).padStart(2, '0')}:${String(minutesStart).padStart(2, '0')} - ${String(hoursEnd).padStart(2, '0')}:${String(minutesEnd).padStart(2, '0')}`
    }
  },

  watch: {
    session: {
      handler (val) {
        this.isFavourite = val && val.favourite
      },
      immediate: true
    }
  },

  created () {
    this.$emit('title', 'Programme')
    this.sessionId = this.$route.params.id
    this.getSession(this.sessionId)
  },

  beforeDestroy () {
    this.clearSession()
  },

  methods: {
    ...mapActions({
      getSession: 'sessions/get',
      clearSession: 'sessions/resetSingle'
    }),
    toggleFavourite () {
      if (!this.user) {
        router.push({ name: 'Login', query: { redirect: `/sessions/${this.session.id}` } })
      } else {
        this.isFavourite = !this.isFavourite
        const favourite = (this.isFavourite) ? 1 : 0
        axios.put(`/user/${this.session.id}/favourite-session/${favourite}`).then((response) => {
          this.getSession(this.sessionId)
        }, () => {})
      }
    },
    handleNoteClick () {
      if (this.session.note && this.session.note.id) {
        this.$router.push(`/my-notes/${this.session.note.id}`)
      } else {
        this.$router.push(`/my-notes/new?session_id=${this.session.id}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.session-wrapper{
    .title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        padding: 0 20px;

        >div{
            display: flex;
            align-items: center;
        }
        .title {
            color: #000;
            letter-spacing: 0.16px;
            font-size: 16px;
        }
        .text-light-blue {
            color: #1c989f;
        }
        .text-orange {
            color: #d75200;
        }
        .favourite {
            color: #000;
            letter-spacing: 0.16px;
            font-size: 16px;

            IMG {
                width: 30px;
                max-width: 30px;
                padding: 4px;
            }
        }
        .note {
          color: #000;
          letter-spacing: 0.16px;
          font-size: 16px;
          margin-right: 10px;

          IMG {
            width: 26px;
            max-width: 30px;
            padding: 4px;
            margin-right: 5px;
            filter: invert(1);
          }
        }
    }

  .time-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 0 20px;

    >div{
      display: flex;
      align-items: center;
    }
    img{
      margin-right: 10px;
    }
  }

  .description-chair-container{
    padding: 20px 20px 20px;
    background-color: #F3F3F3;
  }

  .description-container{
    padding: 10px 20px 20px;
    background-color: #F3F3F3;
  }

  .faculty{
    color: #000;
    padding: 20px;
  }

  .speeches-container{
    .speeches-label {
      padding: 10px 20px;
      color: #000;
      font-weight: 600;
    }
  }
}
</style>
