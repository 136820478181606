<template>
  <div v-if="socialEvent" class="social-event-wrapper">
    <div class="social-event-info-container">
      <social-event-image v-if="socialEvent.image" :social-event="socialEvent" />
      <div class="title">
        {{ socialEvent.title }}
      </div>
      <div class="description" v-html="socialEvent.description" />
    </div>
    <div v-if="socialEvent.id === 4" class="bartender-container">
      <img src="/img/risorse/bartender.png">
    </div>
    <div v-if="socialEvent.sponsors.length > 0">
      <div class="faculty">
        Sponsors
      </div>
      <sponsor-row v-for="(sponsor, index) in socialEvent.sponsors" :key="index" :sponsor="sponsor" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SocialEventImage from '@/components/SocialEvents/SocialEventImage.vue'
import SponsorRow from '@/components/Sponsors/SponsorRow.vue'

export default {
  components: { SponsorRow, SocialEventImage },

  computed: {
    ...mapGetters({
      socialEvent: 'socialEvents/single'
    })
  },

  created () {
    this.$emit('title', 'Social Events')
    this.socialEventId = this.$route.params.id
    this.getSocialEvent(this.socialEventId)
  },

  beforeDestroy () {
    this.clearSocialEvent()
  },

  methods: {
    ...mapActions({
      getSocialEvent: 'socialEvents/get',
      clearSocialEvent: 'socialEvents/resetSingle'
    })
  }
}
</script>

<style scoped lang="scss">
.social-event-wrapper{
  .social-event-info-container{
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title{
      /*font-family: Suisse Intl;*/
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      color: #000;
    }

    .description  {
      padding: 10px 15px;
    }
  }
  .faculty{
    /*font-family: Suisse Intl;*/
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.14px;
    color: #000;
    border-top: 1px solid #F3F3F3;
    border-bottom: 1px solid #F3F3F3;
    padding: 20px;
  }
  .bartender-container {
    padding: 20px 0;
    text-align: center;

    IMG {
      width: 280px;
      height: 132px;
    }
  }
}
</style>
