<template>
  <div class="summary-row" @click="$emit('onRowClick')">
    <div class="labels-container">
      <div>
        <span v-if="summary.id_poster">
          [{{ summary.id_poster }}]
        </span>
        {{ summary.abstract_title }}
      </div>
    </div>
    <img src="/img/risorse/arrow-right-blue-new.svg">
  </div>
</template>

<script>
export default {
  props: {
    summary: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  mounted() {
    this.$emit('rendered')
  }
}
</script>

<style scoped lang="scss">
.summary-row{
  padding: 20px;
  border-top: 1px solid #F3F3F3;
  border-bottom: 1px solid #F3F3F3;
  border-collapse: collapse;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:nth-of-type(even){
    background-color: #F3F3F3;
  }

  .labels-container{
    padding-right: 20px;
    flex: 1;
  }
}
.summary-row:active {
  background-color: #c0c0c0;
  border: 1px solid #a1a1a1;
}
</style>
