<template>
  <div
    class="day-container"
    :class="{'selected': selected && active }"
    @click="selectDay()"
  >
    <div class="day-number">
      {{ day_number | formatDayNumber }}
    </div>
    <div>
      <div class="day-name">
        {{ day_name | formatDayName }}
      </div>
      <div class="month">
        {{ month | formatMonth }}
      </div>
    </div>
  </div>
</template>

<script>
import emitter from 'element-ui/src/mixins/emitter'

export default {
  name: 'DayContainer',

  filters: {
    formatDayNumber (value) {
      return `${String(value).padStart(2, '0')}`
    },

    formatDayName (value) {
      return value
    },

    formatMonth (value) {
      return value
    }
  },

  props: {
    date: {
      type: String,
      default: ''
    },
    selected: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: true
    },
    pageContent: null
  },

  computed: {
    emitter () {
      return emitter
    },
    date_formatted () {
      return new Date(this.date)
    },
    day_number () {
      return this.date_formatted.getDate()
    },
    day_name () {
      return this.date_formatted.toLocaleDateString('EN', { weekday: 'short' })
    },
    month () {
      return this.date_formatted.toLocaleString('EN', { month: 'short' })
    }
  },

  methods: {
    selectDay() {
      localStorage.setItem('sessionsScrollTop', '')
      this.pageContent.scrollTo({ top: 0 })
      this.$emit('daySelected')
    }
  }
}
</script>

<style scoped lang="scss">
.day-container{
  display: flex;
  padding: 5px 10px;
  background-color: #EAEAE7;
  color: #646363;
  border-radius: 7px;
  margin: 0 5px;

  &.selected{
    background-color: #004182;
    color: #FFFFFF;
  }

  .day-number{
    font-size: 16px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .day-labels-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .day-name{
    font-size: 10px;
    text-transform: uppercase;
  }
  .month{
    font-size: 8px;
    text-transform: uppercase;
  }
}

@media screen and (min-width: 380px){
  .day-container{
    .day-number{
      font-size: 22px;
    }
    .day-name{
      font-size: 14px;
    }
    .month{
      font-size: 10px;
    }
  }
}
</style>
