<template>
  <div>
    <div class="faculty">
      SAVED SESSIONS
    </div>
    <div v-if="myProgramme.sessions && myProgramme.sessions.data.length > 0">
      <session-row
        v-for="session in myProgramme.sessions.data"
        :key="session.id"
        :session="session"
        @onNoteClick="handleNoteClick(session)"
        @onFavouriteClick="toggleFavourite(session, $event)"
        @onRowClick="$router.push(`/sessions/${session.id}`)"
      />
    </div>
    <div v-else>
      <div class="no-data">
        No favourite sessions yet...
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import SessionRow from '@/components/MyProgramme/SessionRow.vue'

export default {
  components: { SessionRow },
  computed: {
    ...mapGetters({
      myProgramme: 'myProgramme/items',
      user: 'auth/user'
    })
  },
  created () {
    this.$emit('title', 'My Agenda')
    this.fetchMyProgramme(Object.assign({ sync: true }))
  },
  methods: {
    ...mapActions({
      fetchMyProgramme: 'myProgramme/fetch'
    }),

    handleNoteClick (session) {
      if (session.note && session.note.id) {
        this.$router.push(`/my-notes/${session.note.id}`)
      } else {
        this.$router.push(`/my-notes/new?session_id=${session.id}`)
      }
    },

    toggleFavourite (session, isFavourite) {
      if (!this.user) {
        this.$router.push({ name: 'Login', query: { redirect: `/sessions/${this.session.id}` } })
      } else {
        const favourite = (isFavourite) ? 1 : 0
        axios.put(`/user/${session.id}/favourite-session/${favourite}`).then((response) => {
          // this.fetchMyProgramme(Object.assign({ sync: true }))
        }, () => {})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .days-row{
    display: flex;
    justify-content: space-around;
    padding: 10px;
  }
  .agenda-link {
    margin: 4px 0;
    padding: 6px;
    background-color:  #1d8cf8;
    text-align: right;
    color: white;
    font-weight: bold;
  }
  .faculty{
    /*font-family: Suisse Intl;*/
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.14px;
    color: #000;
    border-top: 1px solid #F3F3F3;
    border-bottom: 1px solid #F3F3F3;
    padding: 15px 10px;
  }
  .no-data{
    font-size: 14px;
    line-height: 18px;
    color: #646363;
    padding: 20px;
  }
</style>
