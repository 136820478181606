<template>
  <div class="speaker-wrapper">
    <div class="speaker-info-container">
      <speaker-image :speaker="speaker" />
      <div class="name">
        {{ socialLink.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      socialLink: 'socialLinks/single'
    }),
    socialLinkImageStyle () {
      return `background: url("${this.socialLink.logo.full_url}");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;`
    }
  },

  created () {
    this.$emit('title', 'Social')
    this.socialLinkId = this.$route.params.id
    this.getSocialLink(this.socialLinkId)
  },

  beforeDestroy () {
    this.clearSocialLink()
  },

  methods: {
    ...mapActions({
      getSocialLink: 'socialLinks/get',
      clearSocialLink: 'socialLinks/resetSingle'
    })
  }
}
</script>

<style scoped lang="scss">
.speaker-wrapper{
  .speaker-info-container{
    display: flex;
    flex-direction: column;
    align-items: center;

    .name{
      /*font-family: Suisse Intl;*/
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.16px;
      color: #000;
    }

    .country{
      /*font: normal normal normal 14px/18px Suisse Intl;*/
      /*font-family: Suisse Intl;*/
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.14px;
      color: #646363;
      margin-bottom: 20px;
    }
  }

  .faculty{
    /*font-family: Suisse Intl;*/
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.14px;
    color: #000;
    border-top: 1px solid #F3F3F3;
    border-bottom: 1px solid #F3F3F3;
    padding: 20px;
  }
}
</style>
