<template>
  <div>
    <vue-horizontal ref="horizontal" :button="false" @scroll="onScroll" @scroll-debounce="onScrollDebounce">
      <div v-for="(dashboardIconPage, iPindex) in iconsPaginated" :key="iPindex" class="icon-page">
        <dashboard-icon
          v-for="(element, dIindex) in iconsPerPage"
          :key="dIindex"
          :extra-class="dashboardIconPage[dIindex] ? dashboardIconPage[dIindex].extraClass : null"
          :label="dashboardIconPage[dIindex] ? dashboardIconPage[dIindex].label : null"
          :to="dashboardIconPage[dIindex] ? dashboardIconPage[dIindex].link : null"
          :img="dashboardIconPage[dIindex] ? dashboardIconPage[dIindex].img : null"
          :external="dashboardIconPage[dIindex] ? dashboardIconPage[dIindex].external : null"
        />
      </div>
    </vue-horizontal>
    <div class="pagination">
      <div
        v-for="i in pages"
        :key="i"
        class="dot"
        :class="{current: i - 1 === index}"
        @click="onPageClick(i - 1)"
      >
        <div />
      </div>
    </div>
  </div>
</template>
<script>
import VueHorizontal from 'vue-horizontal'
import DashboardIcon from '@/components/Dashboard/DashboardIcon.vue'

export default {
  components: { VueHorizontal, DashboardIcon },
  props: {
    iconsPaginated: {
      type: Array,
      default: () => []
    },
    iconsPerPage: {
      type: Number,
      default: 9
    }
  },
  data () {
    return {
      width: 0,
      index: 0
    }
  },
  computed: {
    pages () {
      return this.iconsPaginated.length
    }
  },
  watch: {
    index: {
      handler (val) {
        localStorage.setItem('dashboardPage', val)
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      const storedIndex = localStorage.getItem('dashboardPage')
      if (storedIndex) {
        this.$refs.horizontal.scrollToIndex(storedIndex)
      }
    })
  },
  methods: {
    onScrollDebounce ({ scrollWidth, width, left }) {
      this.width = width
      this.index = Math.round(left / width)
    },
    onScroll ({ left }) {
      this.index = Math.round(left / this.width)
    },
    onPageClick (i) {
      if (i === this.pages - 1) {
        // If last page, always scroll to last item
        this.$refs.horizontal.scrollToIndex(this.iconsPaginated.length - 1)
      } else {
        this.$refs.horizontal.scrollToLeft(i * this.width)
      }
    }
  }
}
</script>

<style lang="scss">
.icon-page {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  padding-top: 10px;
}
.pagination {
  padding-top: 10px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.dot {
  cursor: pointer;
}

.dot > div {
  border-radius: 10px;
  width: 10px;
  height: 10px;
  background: #33333350;
}

.dot:hover > div {
  background: #33333350;
}

.dot.current > div {
  background: #004182;
}
</style>
