<template>
  <div class="note-wrapper">
    <div class="note-info-container">
      <div class="title">
        <input v-model="localNote.title" type="text" placeholder="Title">
      </div>
      <div v-if="session" class="session-details">
        Session:
        <i>{{ session.title }}</i>
      </div>
      <div class="note-content">
        <VueEditor v-model="localNote.content" :editor-toolbar="customToolbar" placeholder="Content ..." />
      </div>
      <div class="note-error" v-if="noteError">
        Titolo e testo non possono essere vuoti
      </div>

      <div class="button-container">
        <base-button v-if="localNote.id" class="mb-2 delete-button" size="lg" @click="handleDelete">
          Delete Note
        </base-button>
        <base-button class="mb-2" size="lg" @click="handleSave">
          Save Note
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor
  },

  data () {
    return {
      noteId: this.$route.params.id !== 'new' ? this.$route.params.id : null,
      noteError: false,
      localNote: {
        user_id: null,
        session_id: this.$route.query.session_id ? this.$route.query.session_id : null,
        title: '',
        content: ''
      },
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ]
      ]
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      note: 'notes/single',
      session: 'sessions/single'
    })
  },

  watch: {
    user: {
      handler () {
        if (this.user) {
          this.localNote.user_id = this.user.id
        }
      },
      immediate: true
    },
    note: {
      handler () {
        if (this.note && this.note.id) {
          this.localNote = { ...this.note }
        }
        if (this.localNote.session_id) {
          this.getSession(this.localNote.session_id)
        }
      },
      immediate: true
    }
  },

  created () {
    this.$emit('title', 'Note')
    if (this.noteId) {
      this.getNote(this.noteId)
    }
  },

  beforeDestroy () {
    this.clearNote()
    this.clearSession()
  },

  methods: {
    ...mapActions({
      getNote: 'notes/get',
      getSession: 'sessions/get',
      createNote: 'notes/create',
      updateNote: 'notes/update',
      deleteNote: 'notes/delete',
      clearNote: 'notes/resetSingle',
      clearSession: 'sessions/resetSingle'
    }),
    handleSave () {
      if (!this.localNote.title || !this.localNote.content) {
        this.noteError = true
      } else {
        if (this.noteId) {
          this.updateNote({id: this.noteId, payload: this.localNote}).then(() => {
            this.$router.push('/my-notes')
          })
        } else {
          this.createNote({payload: this.localNote}).then(() => {
            this.$router.push('/my-notes')
          })
        }
      }
    },
    handleDelete () {
      this.deleteNote(this.localNote.id).then(() => {
        this.$router.push('/my-notes')
      })
    }
  }
}
</script>

<style scoped lang="scss">
.note-wrapper{
  .note-info-container{
    display: flex;
    flex-direction: column;
    align-items: center;

    .title{
      width: 100%;
      padding: 0 15px;
      margin: 15px 0 15px;

      input {
        width: 100%;
        border: 1px solid lightgray;
        padding: 5px 10px;
      }
    }

    .session-details {
      width: 100%;
      padding: 0 15px;
      margin-bottom: 15px;
    }

    .note-content {
      width: 100%;
      padding: 0 15px;
      margin-bottom: 15px;
      height: calc(100vh - 275px);

      .quillWrapper {
        height: calc(100vh - 330px);
      }
    }

    .button-container {
      text-align: center
    }

    button {
      padding: 15px 40px;
      width: 160px;
    }

    .button-container {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        margin: 0 10px;
      }
    }

    .delete-button{
      background: #000;
      color: #fff;
    }

    .note-error {
      font-weight: bold;
      color: darkred;
    }
  }
}
</style>
