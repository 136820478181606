<template>
  <div class="image-container">
    <img :src="socialEventImageStyle">
  </div>
</template>

<script>
export default {
  name: 'SocialEventImage',

  props: {
    socialEvent: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    socialEventImageStyle () {
      return (this.socialEvent.image) ? this.socialEvent.image.full_url : '/img/risorse/company.svg'
    }
  }
}
</script>

<style scoped>
  .image-container{
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
  }
  IMG {
    width: 90%;
    max-height: 150px;
  }
</style>
